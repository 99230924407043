export const INIT_STICKIES = ROOT_ELEMENT => {
  const elementsToObserve = ROOT_ELEMENT.querySelectorAll('[data-sticky-content]');
  if (elementsToObserve) {
    const observerOptions = {
      ROOT_ELEMENT: ROOT_ELEMENT,
      rootMargin: '-50% 0px -50% 0px'
    }

    let observer = new IntersectionObserver(entries => {

      const imagesWrapper = ROOT_ELEMENT.querySelector('[data-sticky-image-wrapper]');
      const images = imagesWrapper.querySelectorAll('[data-sticky-image-wrapper] [data-trigger]');

      entries.forEach(entry => {
        if (entry.isIntersecting)
          images.forEach(image => {
            if (image.dataset.trigger === entry.target.dataset.trigger) image.dataset.visible = 'true';
            else image.dataset.visible = 'false';
          });
      });
    }, observerOptions);

    elementsToObserve.forEach(elementToObserve => observer.observe(elementToObserve));

    window.addEventListener('resize', () => {
      elementsToObserve.forEach(elementToObserve => observer.observe(elementToObserve));
    });
  }
}
